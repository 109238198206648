import React from "react";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DEFAULT_ICON, ICONS } from "./file-icon.constants";
import { IProps } from "./file-icon.types";

/**
 * Show an icon appropriate for an extension.
 */
export default class FileIcon extends React.PureComponent<IProps> {
    static defaultProps = { isLoading: false };

    /**
     * Render an appropriate icon for an attachment based on its file extension.
     * @param props Props containing the file name and loading state.
     * @returns     File icon.
     */
    render(): JSX.Element {
        const { isLoading, fileName } = this.props;
        if (isLoading === true) {
            return <FontAwesomeIcon fixedWidth={true} icon={faSpinner} spin={true} />;
        }

        // The icon will depend on the file extension.
        const fileExtension = fileName.split(".").pop()!;

        // Loop through the icons and return one if it matches.
        for (let index = 0; index < ICONS.length; index++) {
            if (ICONS[index].pattern.test(fileExtension)) {
                return <FontAwesomeIcon fixedWidth={typeof isLoading !== "undefined"} icon={ICONS[index].icon} />;
            }
        }

        // The file extension must be unrecognised, so just return the default.
        return <FontAwesomeIcon fixedWidth={typeof isLoading !== "undefined"} icon={DEFAULT_ICON} />;
    }
}

/**
 * Empty strings are never sent to the backend, they are also sent as nulls. This method determines if a string is empty
 * and returns a null if it is, or the trimmed string otherwise.
 *
 * @param value String value.
 * @returns     Null or string value.
 *
 * @example
 *
 * // Returns 'value':
 * trimOrNull(' value ');
 *
 * // Returns null:
 * trimOrNull(' ');
 */
export function trimOrNull(value: string | null): string | null {
    if (typeof value !== "string") {
        return null;
    }
    const trimmed = value.trim();
    return trimmed.length === 0 ? null : trimmed;
}

import React, { FunctionComponent, memo, useState } from "react";
import { DEFAULT_OPTIONS, useLayer } from "react-laag";
import ResizeObserver from "resize-observer-polyfill";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortAmountDown, faSortAmountDownAlt, faUndo } from "@fortawesome/free-solid-svg-icons";

import { IProps, IPopoverItem } from "./popover.types";
/**
 * Popover. Display a dropdown select that positions automaticaly when is opened
 * @param props.children        Elements or components displayed inside
 * @param props.className       Style classes
 * @param props.items           List of values used for options in dropdown
 * @param props.itemSelected    Current option selected
 * @param props.onReset         Optional. Callback function for clearing selected filter
 * @param props.onSelect        Callback function for selecting an item
 * @param props.onSort          Optional. Callback function for sorting selected filter
 * @param props.placement       Optional. Placement position of the popover
 * @param props.triggerOffset   Optional. Offset posiion from trigger element
 * @param props.title           Optional. Group title
 */

const Popover: FunctionComponent<IProps> = ({
    activeSort,
    children,
    className,
    items,
    itemSelected,
    onSelect,
    onReset,
    onSort,
    placement = DEFAULT_OPTIONS.placement,
    triggerOffset = 8,
    title,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const closePopover = () => setIsOpen(false);

    const { layerProps, renderLayer, triggerProps } = useLayer({
        auto: true,
        isOpen: isOpen,
        overflowContainer: true,
        onDisappear: closePopover,
        onOutsideClick: closePopover,
        placement,
        ResizeObserver,
        triggerOffset,
    });

    const select = (item: IPopoverItem) => {
        onSelect(item);
        closePopover();
    };

    const popover = (
        <div className={classNames("popover__list", className)} {...layerProps}>
            <div className="popover__list-header">
                {typeof title === "string" && <div className="popover__list-title">{title}</div>}
                {typeof onReset === "function" && (
                    <button
                        type="button"
                        className="popover__list-reset"
                        onClick={() => {
                            onReset();
                            closePopover();
                        }}
                    >
                        <FontAwesomeIcon icon={faUndo} fixedWidth size="sm" />
                    </button>
                )}
            </div>
            {typeof onSort === "function" && (
                <>
                    <hr />
                    <div className="popover__list-sort">
                        <div
                            onClick={() => {
                                onSort("asc");
                                closePopover();
                            }}
                            className={classNames("popover__list-sort-item", {
                                "popover__list-sort-item__selected": activeSort === "asc",
                            })}
                        >
                            <FontAwesomeIcon icon={faSortAmountDownAlt} size="sm" fixedWidth />
                            <span>Sort ascending</span>
                        </div>
                        <div
                            onClick={() => {
                                onSort("desc");
                                closePopover();
                            }}
                            className={classNames("popover__list-sort-item", {
                                "popover__list-sort-item__selected": activeSort === "desc",
                            })}
                        >
                            <FontAwesomeIcon icon={faSortAmountDown} size="sm" fixedWidth />
                            <span>Sort descending</span>
                        </div>
                    </div>
                    <hr />
                </>
            )}
            <div className="popover__list-items">
                {items.map((option, index) => (
                    <div
                        key={`${option}${index}`}
                        className={classNames("popover__list-item", `popover__list-item__${option.key}`, {
                            "popover__list-item__selected": itemSelected?.key === option.key,
                        })}
                        onClick={() => select(option)}
                        title={option.label}
                    >
                        {typeof option.icon !== "undefined" && <FontAwesomeIcon icon={option.icon} />}
                        {option.label}
                    </div>
                ))}
            </div>
        </div>
    );

    return (
        <>
            <div {...triggerProps} onClick={() => setIsOpen(!isOpen)}>
                {children}
            </div>
            {renderLayer(isOpen && popover)}
        </>
    );
};

export default memo(Popover);

import classNames from "classnames";
import React, { PureComponent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IDefaultProps, IButtonProps } from "./button.types";
import { LOADING_ICON } from "./button.constants";

/**
 * Themable button with an icon.
 */
export class Button extends PureComponent<IButtonProps> {
    static defaultProps: IDefaultProps = { isLoading: false, isSmall: false, outline: false };

    /**
     * Render a button that is coloured to suit the client.
     * @returns Button element.
     */
    render(): JSX.Element {
        const {
            children,
            className,
            icon,
            isIconOnly,
            isLoading,
            isSmall,
            isUnstyled,
            outline,
            primaryColour,
            size,
            styleName,
            ...otherProps
        } = this.props;

        // The button may be styled or not depending on where it's used.
        const style =
            typeof primaryColour !== "string"
                ? undefined
                : {
                      backgroundColor: outline || isLoading ? "transparent" : primaryColour,
                      color: outline || isLoading ? primaryColour : undefined,
                      border: `1px solid ${primaryColour}`,
                  };

        return (
            <button
                className={classNames(className, {
                    button: !isUnstyled,
                    "button--outline": outline || isLoading,
                    "button--icon": !isUnstyled && (isIconOnly === true || typeof children === "undefined"),
                    "button--small": isSmall,
                    [`button--${styleName}`]: typeof styleName === "string",
                    [`button--${size}`]: typeof size === "string",
                })}
                disabled={isLoading}
                style={style}
                {...otherProps}
            >
                <FontAwesomeIcon
                    fixedWidth={typeof isLoading !== "undefined"}
                    icon={isLoading ? LOADING_ICON : icon}
                    spin={isLoading}
                />
                {children && children}
            </button>
        );
    }
}

export default Button;

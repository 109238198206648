import React from "react";
import { faSquare } from "@fortawesome/free-regular-svg-icons/faSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Unchecked radio button icon.
 * @returns Icon.
 */
const UncheckedIcon = () => <FontAwesomeIcon fixedWidth={true} icon={faSquare} size="lg" />;

export default UncheckedIcon;

import React from "react";
import { ErrorMessage } from "formik";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createFieldErrorId } from "@edgetier/utilities";

import { IProps } from "./field-error.types";

/**
 * Render a field error message with an icon.
 * @param props.name Field name.
 * @returns          Formik error message.
 */
const FieldError = ({ name }: IProps) => {
    return (
        <ErrorMessage name={name}>
            {(message) => (
                <div className="field__error" id={createFieldErrorId(name)}>
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                    {message}
                </div>
            )}
        </ErrorMessage>
    );
};

export default FieldError;

import React, { FunctionComponent, memo } from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import { IProps } from "./markdown.types";
import { preserveNewlines } from "./markdown.utilities";

/**
 * Render markdown. This is just a wrapper to provide styles.
 * @param props.children Text to convert to markdown.
 */
const Markdown: FunctionComponent<IProps> = ({ linkTarget = "_blank", markdownString }) => (
    <div className="markdown">
        <ReactMarkdown
            renderers={{
                link: ({ children, href }) => (
                    <a
                        href={href}
                        target={linkTarget}
                        rel={linkTarget === "_blank" ? "noreferrer noopener" : undefined}
                    >
                        {children}
                    </a>
                ),
            }}
            plugins={[gfm]}
        >
            {preserveNewlines(markdownString)}
        </ReactMarkdown>
    </div>
);

export default memo(Markdown);

import { AxiosInstance, AxiosRequestConfig } from "axios";
import { IPaginated } from "@edgetier/types";

export const DEFAULT_LIMIT = 50;

/**
 * Get all items for a paginated URL.
 * @param url URL to request.
 */
export const createGetAllPaginatedItems = (axios: AxiosInstance) => async <T extends {}>(
    url: string,
    configuration: AxiosRequestConfig = {}
) => {
    let response = await axios.get<IPaginated<T>>(url, {
        ...configuration,
        params: { ...configuration.params, limit: configuration.params?.limit ?? DEFAULT_LIMIT },
    });
    const items: T[] = response.data.items;

    // Keep requesting until there are no more pages.
    while (typeof response.data.pagination?.nextPage === "string") {
        response = await axios.get(response.data.pagination.nextPage);
        items.push(...response.data.items);
    }

    return items;
};

import React from "react";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons/faCheckSquare";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Checked checkbox button icon.
 * @returns Icon.
 */
const CheckedIcon = () => <FontAwesomeIcon fixedWidth={true} icon={faCheckSquare} size="lg" />;

export default CheckedIcon;

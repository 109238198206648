import React from "react";
import { faCircle } from "@fortawesome/free-regular-svg-icons/faCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Unchecked radio button icon.
 * @returns Icon.
 */
const UncheckedIcon = () => <FontAwesomeIcon fixedWidth={true} icon={faCircle} size="lg" />;

export default UncheckedIcon;
